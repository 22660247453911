.fadein {
  animation: fadein 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.border-highlight:hover {
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 16%), 0 8px 10px 1px rgb(0 0 0 / 10%),
    0 3px 14px 2px rgb(0 0 0 / 8%);
  transition: 0.1s;
}

.border-highlight-choosen:hover {
  box-shadow: 0px 0px 6px 6px rgba(3, 147, 62, 0.3);
  transition: 0.1s;
}

.popup:hover {
  animation: popup-animate 0.1s;
  animation-fill-mode: forwards;
}

@keyframes popup-animate {
  100% {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 16%), 0 8px 10px 1px rgb(0 0 0 / 10%),
      0 3px 14px 2px rgb(0 0 0 / 8%);
  }
}
.choosen-popup:hover {
  animation: choosen-popup-animate 0.1s;
  animation-fill-mode: forwards;
}

@keyframes choosen-popup-animate {
  100% {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
    box-shadow: 0px 0px 6px 6px rgba(3, 147, 62, 0.3);
  }
}

.hide {
  display: none;
}

/* FLIP CARD */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #fff;
}

/* Style the back side */
.flip-card-back {
  background-color: #fff;
  transform: rotateY(180deg);
  text-align: center;
}

.choosen {
  box-shadow: 0px 0px 7px 7px rgba(3, 147, 62, 0.3);
}
